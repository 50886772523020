.container{
  margin: 0;
  padding: 0;
  font-family: 'RotisSemiSerifBold', sans-serif;
  background-color: #2C3685;
  box-sizing: border-box;
  overflow: hidden; /* Prevents page from being scrollable */
  overflow-x: hidden; /* Prevents horizontal scroll */
  min-height: 100vh;
}
.login-container {
  background-color: #2C3685;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(209, 205, 205, 0.1);
  max-width: 700px; /* Made form larger */
  width: 80%;
  text-align: center;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.login-title {
  font-family: 'FarTwoLight2op', serif;
  font-size: 28px;
  color: #fff;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  text-align: left;
}

.input-group label {
  width: 100%;
  font-family: 'RotisSemiSerifBold', sans-serif;
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'RotisSemiSerifBold', sans-serif;
  font-size: 16px;
}

.login-button {
  width: fit-content;
  background-color: #4684C4;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

.login-button:hover {
  background-color: #365f9e;
}

@media (max-width: 768px) {
  .login-title {
    font-size: 24px;
  }

  .login-container {
    padding: 30px;
  }
}
