
@font-face {
  font-family: "FarTwoLight2op";
  src: url("./fonts/arfonts-far-two-light-2op.ttf") format("truetype");
}

@font-face {
  font-family: "RotisSemiSerifBold";
  src: url("./fonts/RotisSemiSerifStd-Bold.otf") format("opentype");
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "RotisSemiSerifBold", sans-serif;
  background-color: #f4f4f9;
  box-sizing: border-box;
  /* overflow: hidden; Prevents page from being scrollable */
  overflow-x: hidden; /* Prevents horizontal scroll */
}

.navbar {
  background-color: white;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping for responsive design */
}

.logo {
  width: 150px;
}

.nav-items {
  width: 50%;
  margin-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-button {
  width: fit-content;
  padding: 7px 10px;
  background-color: transparent;
  color: #2c3685;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
}

.nav-button:hover,
.nav-button.active {
  background-color: #2c3685;
  color: white;
}

/* Responsive Navbar for Small Screens */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Stack the logo and nav items vertically */
    padding: 10px 0;
  }

  .logo {
    width: 120px; /* Slightly smaller logo for small screens */
    margin-bottom: 10px;
  }

  .nav-items {
    width: 100%; /* Full width for nav items */
    flex-direction: column; /* Stack nav items vertically */
    margin: 0;
  }

  .nav-button {
    width: 90%; /* Full width buttons */
    text-align: center; /* Center text */
    margin: 5px 0; /* Add spacing between buttons */
  }
}

/* Additional media query for very small screens */
@media (max-width: 480px) {
  .logo {
    width: 100px; /* Smaller logo for very small screens */
  }

  .nav-button {
    font-size: 16px; /* Smaller font for buttons */
    padding: 5px 8px;
  }
}

.container {
  text-align: center;
  padding: 20px;
  background-color: #2c3685;
  color: white;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.title {
  font-family: "FarTwoLight2op", serif;
  font-size: 36px;
  margin-bottom: 20px;
}

form {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  max-width: 700px; /* Made form larger */
  width: 80%;
  text-align: left;
  margin: 20px 0;
}

label {
  display: block;
  margin-bottom: 10px;
  color: #2c3685 !important;
  font-size: 18px;
}

input {
  width: 90%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "RotisSemiSerifBold", sans-serif;
  font-size: 16px;
}
input:focus {
  outline: #4684c4 1.5px solid;
}
.numberOfTickets {
  width: 6rem;
}
button {
  background-color: #4684c4;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  width: 100%;
}

.increase {
  margin-left: 10px;
  margin-bottom: 15px;
}

.decrease {
  margin-right: 10px;
  margin-bottom: 15px;
}

button:disabled {
  background-color: #ccc;
}

h2 {
  font-family: "RotisSemiSerifBold", sans-serif;
  font-size: 24px;
  margin-bottom: 10px;
}

p,
ul {
  font-family: "RotisSemiSerifBold", sans-serif;
  color: #333;
  font-size: 16px;
}

.terms {
  text-align: left;
  max-width: 600px;
  margin: 0 auto 20px auto;
  padding: 30px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  overflow-y: scroll; /* Makes terms and conditions box scrollable */
  max-height: 400px; /* Limits height of the terms box */
}

.ticket-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
}

.ticket-selector button {
  width: 50px;
  height: 50px;
  font-size: 20px;
  background-color: #4684c4;
  color: white;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the + and - signs */
}

.footer {
  background-color: #fff;
  /* padding: 10px; */
  text-align: center;
  min-width: 100vw;
  position: absolute;
  bottom: 0;
  color: #2c3685;
  margin-top: 50px;
}

.footer a {
  color: #4684c4;
  text-decoration: none;
  font-weight: bold;
}

@media (max-width: 768px) {
  .title {
    font-size: 28px;
  }

  form {
    padding: 30px;
  }

  .ticket-selector button {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
}

/* ====================== */
.flex--col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoScan {
  max-width: fit-content;
  max-height: fit-content;
  overflow: hidden;
  border-radius: 20px;
}
.videoScan video {
  height: 40vh;
  width: fit-content;
  object-position: center;
  border-radius: 20px;
}
.AABtn {
  width: fit-content;
  padding: 1rem 3.25rem;
}
